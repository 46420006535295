import React from 'react';
import Carousel from 'react-material-ui-carousel';
import {Button, Divider} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Green from './img/sustainable.jpg';
import Illus from './img/illus.jpg';
import Burn from './img/burn.jpg';
import Burner from './img/slider/charity.jpg';
import Bg from './img/pellets_1.jpg';
import Energy from './img/energy.jpg';
import form1 from './3AM_FORMS.pdf';
import form2 from './3AM_Liability.pdf';
import Para0 from './img/slider/image1.jpg';
import Para1 from './img/slider/image.jpg';
import Countdown from './count';
import Pic1 from './img/trip/2022.jpg';
import Pic2 from './img/trip/give1.jpg';
import Pic3 from './img/trip/ethio4.jpg';
import Pic4 from './img/trip/give3.jpg';
import Pic5 from './img/trip/kenya.jpg';
import Pic6 from './img/trip/2022_0.jpg';
import Pic9 from './img/FB_IMG_1689787670548.jpg';
import Pict from './img/trip/tanzania.jpeg';
import Para3 from './img/slide3.jpeg';
import Grid from '@material-ui/core/Grid';
import { Parallax } from "react-parallax";
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import ImageListItemBar from '@material-ui/core/ImageListItemBar';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import itemData from './data';
import Typography from '@material-ui/core/Typography';
import Vid from './videos/churchbuilding.mp4';
import Vid2 from './videos/Schoolbuilding.mp4';
import Vid1 from './videos/tanzania.mp4';
import imager from './img/outreach.png';
import Community from './img/slider/community.svg';
import Evangelism from './img/slider/evengelism.svg';
import Medical from './img/slider/medical.svg';
import AOS from 'aos';
import "aos/dist/aos.css";
import Carousel_ from 'react-grid-carousel'

import ButtonBase from '@material-ui/core/ButtonBase';
import  {
  Link
} from 'react-router-dom';
const insideStyles = {
//  background: "white",
  padding: "0%",
  position: "absolute",
  top: "49%",
  left: "50%",
  transform: "translate(-50%,-50%)",
  fontSize:"272%",
  color:"white",
    textAlign:"center",
};    

const parallaxside={
   
//  backgroundImage: "linear-gradient(315deg, #e2ae01 50%, #64f5d5 100%)",
    padding:20,
    textAlign:'left',
    color:"black"
    
    
}

const useStyles=makeStyles((theme)=>({
    image: {
    position: 'relative',
    height: 300,
    [theme.breakpoints.down('xs')]: {
      width: '100% !important', // Overrides inline-style
      height: 100,
    },
    '&:hover, &$focusVisible': {
      zIndex: 0,
      '& $imageBackdrop': {
        opacity: 0.15,
      },
      '& $imageMarked': {
        opacity: 0,
      },
      '& $imageTitle': {
        border: '4px solid currentColor',
      },
    },
  },
  focusVisible: {},
  imageButton: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
  },
  imageSrc: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%',
  },
  imageBackdrop: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.4,
    transition: theme.transitions.create('opacity'),
  },
  imageTitle: {
    position: 'relative',
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(1) + 6}px`,
  },
  imageMarked: {
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 9px)',
    transition: theme.transitions.create('opacity'),
  },

    root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  imageList: {
    width: '100%',
    height: '100%',
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
    
    
    
    caro:{ 
        
        backgroundRepeat: 'no-repeat', 
        backgroundSize:'cover',
        backgroundPosition:'center',  
        [theme.breakpoints.up('xs')]: {
        height:240,
            paddingTop: '15%'
    },   
      [theme.breakpoints.up('sm')]: {
      height:400,
          paddingTop: '15%'
         
    }
    }
}));


const images = [
  {
    url: Burner,
    title: 'Donate Here',
    width: '100%',
  }
];


const Caroo=()=>{

  return(

    <Carousel_ cols={3} rows={1} gap={10} autoplay={3000} loop>
      <Carousel_.Item>
        <img width="100%" src={Pict} style={{borderRadius:"8px"}}/>
        <br/>
        <p style={{margin:0}}><b>Community outreach In Tanzania (2024)</b></p>
      </Carousel_.Item>
      <Carousel_.Item>
        <img width="100%" src={Pic9} style={{borderRadius:"8px"}}/>
        <br/>
        <p style={{margin:0}}><b>Community outreach In Tanzania (2023)</b></p>
      </Carousel_.Item>
      <Carousel_.Item>
        <img width="100%" src={Pic1} style={{borderRadius:"8px"}}/>
        <br/>
        <p style={{margin:0}}><b>Evangelism In Madagascar</b></p>
      </Carousel_.Item>
      
      <Carousel_.Item>
        <img width="100%" src={Pic2}   style={{borderRadius:"6px"}} />
        <br/>
        <p style={{margin:0}}><b>Community Outreach In Ghana</b></p>
      </Carousel_.Item>
      <Carousel_.Item>
        <img width="100%" src={Pic3}  style={{borderRadius:"6px"}} />
        <br/>
        <p style={{margin:0}}><b>Evangelism in Ethiopia</b></p>
      </Carousel_.Item> 
      <Carousel_.Item>
        <img width="100%" src={Pic4}  style={{borderRadius:"6px"}} />
        <br/>
        <p style={{margin:0}}><b>Community Outreach In India</b></p>
      </Carousel_.Item>  
      <Carousel_.Item>
        <img width="100%" src={Pic5}  style={{borderRadius:"6px"}} />
        <br/>
        <p style={{margin:0}}><b>Community Outreach In Kenya</b></p>
      </Carousel_.Item> 
      <Carousel_.Item>
        <img width="100%" src={Pic6}  style={{borderRadius:"6px"}} />
        <br/>
        <p style={{margin:0}}><b>Evangelism In Madagascar</b></p>
      </Carousel_.Item>
     
      {/* ... */}
    </Carousel_>

  )

}


const Caro2=()=>{

  return(

    <Carousel_ cols={1} rows={1} gap={10}   loop>
      <Carousel_.Item>
      <p style={{margin:4, fontSize:25, textAlign:"center"}}><b>Mission Tanzania (2024)</b></p>

      <video  src={Vid1} controls width="100%"  style={{borderRadius:"10px"}} />
                  <br/>
      </Carousel_.Item>
       <Carousel_.Item>
      <p style={{margin:4, fontSize:25, textAlign:"center"}}><b>Church Building Project</b></p>

      <video  src={Vid} controls width="100%"  style={{borderRadius:"10px"}} />
                  <br/>
      </Carousel_.Item>
      
      <Carousel_.Item>
      <p style={{margin:4, fontSize:25, textAlign:"center"}}><b>School Building Project</b></p>

        <video width="100%" src={Vid2} controls  style={{borderRadius:"10px"}} />
        <br/>
      </Carousel_.Item>
      
     
      {/* ... */}
    </Carousel_>

  )

}


function Example(props){
    AOS.init({
    // initialise with other settings
    duration : 700
  });
    const classes = useStyles();
    
    var items = [
        {
            name: <h1 style={{color:"white", fontSize:"48px", marginTop:30}} data-aos={"flip-right"}><span style={{ paddingLeft:12, paddingRight:12}}>-3<span style={{color:'gold'}}>AM</span> Mission Ministry- </span></h1>,
            image: Para0,
            description: "Welcome to"
        },  
        {
          name: <h1 style={{color:"white", fontSize:"48px", marginTop:30,}} data-aos={"flip-right"}><span style={{ paddingLeft:12, paddingRight:12,backgroundColor:"rgba(0,0,0,0.5)"}}> Let's Reach Out To The World </span></h1>,
          image: Para1,
          description: ""
        },
        
    ]

    return (
        
        <div>
        <Carousel swipe={true} indicators={false} touch={true} animation={"slide"} >
            {
                items.map( (item, i) => <Item key={i} item={item} /> )
            }
        </Carousel>
    <div>
      {/* <h2>MISSION TANZANIA 2024 COUNTDOWN</h2>
      <h2>JUNE 25TH - JULY 6TH, 2024</h2>
      */}
      {/* <Grid container>

<Grid lg={2} sm={12} md={12}>  </Grid>
<Grid lg={8} sm={12} md={12}>  <Countdown/> </Grid>
<Grid lg={2} sm={12} md={12}>  </Grid>

      </Grid> */}
  
      <br/>
      {/* <Divider></Divider> */}
    <Container  style={{textAlign:"left", marginTop:"50px", backgroundColor:""}}> 
    <h2 className="MuiTypography-h4" data-aos="fade-in" style={{textAlign:"center"}}>
        PROJECTS
            </h2>


            <Grid container spacing={0} >
        <Grid item xs={12} sm={6} md={6} style={parallaxside} className="sms_m">

          <h3 className="MuiTypography-h4"  style={{marginTop:10, color:'black'}} data-aos="fade-up">Project Description</h3>
            <ol style={{color:"black"}}>
          <li>We are building 3 additional classrooms, an office for principal, desks and benches, improve toilets and add an overhead water tank to collect rain water for daily use at SDA Elementary School in the district of Infanadiana in Madagascar</li>
         <br/>
          <li>We are building a new church for the SDA church at Antadinambo in Madagascar which has a little building to accomodate church members.</li>
         </ol>         </Grid> 

        <Grid item xs={12} sm={6} md={6} style={{textAlign:'left'}}>

            
           <Parallax bgImage={Burner} strength={250}>
           <Link to="/donate">
      {images.map((image) => (
        <ButtonBase
          focusRipple
          key={image.title}
          className={classes.image}
          focusVisibleClassName={classes.focusVisible}
          style={{
            width: image.width,
          }}
        >
          <span data-aos={"zoom-in"}
            className={classes.imageSrc}
            style={{
              backgroundColor:"rgba(0,0,0,0.1)",
            }}
          />
          <span className={classes.imageBackdrop} />
          <span className={classes.imageButton}>
            <Typography
              component="span"
              variant="subtitle1"
              color="inherit"
              style={{fontSize:35}}
              className={classes.imageTitle}
            >
              {image.title}
              <span className={classes.imageMarked} />
            </Typography>
          </span>
        </ButtonBase>
      ))}
  
</Link>            
        
    </Parallax>  
            
            
            
         </Grid>
        </Grid>




    </Container> 
<br/>
<br/>
<br/>
<Container  maxWidth="lg" style={{textAlign:"left"}}> 
<Container maxWidth="md">

  <Caro2/> 
<br/>
<br/>
</Container>

       



    </Container> 
     
  <br/>
  <br/>
 
        
        <div className="sms_m">
        <br/>
        <h2 className="MuiTypography-h4" style={{textAlign:"center"}} data-aos="fade-up">
           <b> Our boarders are extended throughout the World </b>

            </h2>
  <br/>
  <Caroo data-aos="fade-up"/>
<br/>
<br/>
<Container  maxWidth="md" style={{textAlign:"left"}}   > 
          

<img src={imager} width="100%" />







    </Container> 


   


    <br/>
    <br/>
     </div>
  <br/>
  <br/>
  <br/>

     
    </div>
    <h2 className="MuiTypography-h4" data-aos="fade-up" style={{textAlign:"center", fontWeight:"bolder"}}>
       What We Do            </h2>
    
       <Grid container spacing={1} style={{marginTop:40,marginBottom:80}}>
        <Grid item xs={12} sm={4} md={4} style={{textAlign:'left', marginTop:20}}  data-aos="fade-up">
                        
           <Card className={classes.root}>
      <CardActionArea>
        <CardMedia
       
        />
        <div align="center"><img src={Community} width="250px"/></div>
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2" align="center">
          Community Outreach

          </Typography>
          <Typography variant="body2" color="text" component="p">
          This program is based on the Gospel of Jesus, Matthew 25: 42, 43 & 45 For I was hungry and you gave me no food, I was thirsty and you gave me no drink.          </Typography>
        </CardContent>
      </CardActionArea>
  
    </Card> 
            
            
          
         </Grid> 

  <Grid item xs={12} sm={4} md={4} style={{textAlign:'left', marginTop:20}}  data-aos="fade-up">

            
            
           <Card className={classes.root}>
      <CardActionArea>
        <CardMedia
       
        />
        <div align="center">
          <br/>
          <br/>
          <img src={Evangelism} width="333px"/>
       </div>
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2" align="center">
          Evangelism
          </Typography>
          <Typography variant="body2" color="text" component="p">
          Go ye therefore, and make disciples of All Nations, baptizing them in the name of the Father and of the Son and the Holy Spirit. Matthew 28:18-20          </Typography>
     
        </CardContent>
      </CardActionArea>
      
    </Card> 
            
            
          
         </Grid> 

  <Grid item xs={12} sm={4} md={4} style={{textAlign:'left', marginTop:20}}  data-aos="fade-up">

            
            
           <Card className={classes.root}>
      <CardActionArea>
        <CardMedia
       
        />
        <div align="center"><img src={Medical} width="250px"/></div>
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2" align="center">
          Health and Medical Outreach
          </Typography>
          <Typography variant="body2" color="text" component="p">
          We promote the 8 Bible Secrets for a longer and stronger life for an Amazing Health Benefits when we use The Newstart Lifestyle          </Typography>
        </CardContent>
      </CardActionArea>
      
    </Card> 
            
            
          
         </Grid> 




        </Grid> 
    


            
<Container  maxWidth="lg" style={{textAlign:"left"}}>
    
   

    
    
    
</Container>


<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3104.8484336332685!2d-76.81632558464938!3d38.90458117956952!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b7eab2795cc2e9%3A0x3b7e3088d8779114!2s1011%20Winged%20Foot%20Dr%2C%20Bowie%2C%20MD%2020721%2C%20USA!5e0!3m2!1sen!2sgh!4v1606680803222!5m2!1sen!2sgh" width="100%" height="400" style={{border:0, allowfullscreen:""}} title="location" loading="lazy"></iframe>
    </div>

      
    )
}

function Item(props)



{
    const classes=useStyles()
    return (
        
        <div className={classes.caro} style={{backgroundImage: `url(${props.item.image})`}}>
          <p style={{color:"white", fontSize:"40px", fontWeight:"bolder", margin:0}} data-aos={"zoom-in"}>{props.item.description}</p>

            {props.item.name} 

          
        
        
        </div>
        
        
    

    )
}


export default Example


